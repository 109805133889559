import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Login from "../components/login"

// See fix for location not available in ssr here: https://vidler.app/blog/javascript/gatsby-window-location-not-available-during-ssr/
let pathname = typeof window !== "undefined" ? window.location.pathname : ""

const app = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} location={pathname}/>
      <Login path="/app/login" />
    </Router>
  </Layout>
)
export default app